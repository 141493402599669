
import { defineComponent } from "vue";
import DesktopFooterSocial from "./DesktopFooterSocial.vue";

export default defineComponent({
  name: "DesktopFooter",
  components: {
    DesktopFooterSocial,
  },
});
