
import { defineComponent } from "vue";
import SlideUpDown from "vue3-slide-up-down";
import { useToggle } from "@vueuse/core";

export default defineComponent({
  name: "FAQItem",
  components: {
    SlideUpDown,
  },
  setup() {
    const [value, toggle] = useToggle(false);

    return {
      isOpen: value,
      toggle,
    };
  },
});
