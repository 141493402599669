import { renderSlot as _renderSlot, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_link = _resolveComponent("router-link")!

  return (_ctx.to)
    ? (_openBlock(), _createBlock(_component_router_link, {
        key: 0,
        to: _ctx.to,
        class: "header-button"
      }, {
        default: _withCtx(() => [
          _renderSlot(_ctx.$slots, "default", {}, undefined, true)
        ]),
        _: 3
      }, 8, ["to"]))
    : (_openBlock(), _createElementBlock("button", {
        key: 1,
        class: "header-button",
        onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('click')))
      }, [
        _renderSlot(_ctx.$slots, "default", {}, undefined, true)
      ]))
}