import { resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "row" }
const _hoisted_2 = { class: "col-12 col-lg-7" }
const _hoisted_3 = { class: "col-12 col-lg-5" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_contact_details = _resolveComponent("contact-details")!
  const _component_contact_form = _resolveComponent("contact-form")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass([_ctx.breakpoints.isMobile && 'main-container my-4 bg-white pt-4'])
  }, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _createVNode(_component_contact_details, {
          class: _normalizeClass([_ctx.breakpoints.isMobile && 'mb-5'])
        }, null, 8, ["class"])
      ]),
      _createElementVNode("div", _hoisted_3, [
        _createVNode(_component_contact_form)
      ])
    ])
  ], 2))
}