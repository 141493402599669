
import { defineComponent } from "vue";

export default defineComponent({
  name: "Logo",
  props: {
    dark: Boolean,
  },
  setup(props) {
    return {
      fill: props.dark ? "#0c1229" : "#fff",
    };
  },
});
