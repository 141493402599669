import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_mobile_footer = _resolveComponent("mobile-footer")!
  const _component_desktop_footer = _resolveComponent("desktop-footer")!

  return (_ctx.breakpoints.isMobile)
    ? (_openBlock(), _createBlock(_component_mobile_footer, { key: 0 }))
    : (_ctx.breakpoints.isDesktop)
      ? (_openBlock(), _createBlock(_component_desktop_footer, { key: 1 }))
      : _createCommentVNode("", true)
}