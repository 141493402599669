
import { defineComponent, watch } from "vue";
import { useScriptTag } from "@vueuse/core";
import ContactDetails from "@/components/Page/Contact/ContactDetails.vue";
import ContactForm from "@/components/Page/Contact/ContactForm.vue";
import useMetaTitle from "@/composables/useMetaTitle";
import useBreakpoints from "@/composables/useBreakpoints";
import useConfig from "@/composables/useConfig";

export default defineComponent({
  name: "ContactPage",
  components: {
    ContactDetails,
    ContactForm,
  },
  setup() {
    useMetaTitle("تماس با ما");
    const breakpoints = useBreakpoints();
    const { googlerecaptcha_site_key } = useConfig();

    watch(
      () => googlerecaptcha_site_key.value,
      (value) => {
        if (value) {
          useScriptTag(
            `https://www.google.com/recaptcha/api.js?render=${googlerecaptcha_site_key.value}`
          );
        }
      },
      {
        immediate: true,
      }
    );

    return {
      breakpoints,
    };
  },
});
