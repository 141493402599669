
import { defineComponent } from "vue";
import ContactDetailItem from "./ContactDetailItem.vue";
import ContactSocialMedia from "./ContactSocialMedia.vue";

export default defineComponent({
  name: "ContactDetails",
  components: {
    ContactDetailItem,
    ContactSocialMedia,
  },
});
