import { renderSlot as _renderSlot, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-063d18b5"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "contact-detail-item" }
const _hoisted_2 = { class: "contact-detail-item__icon" }
const _hoisted_3 = { class: "contact-detail-item__content" }
const _hoisted_4 = { class: "contact-detail-item__label" }
const _hoisted_5 = { class: "contact-detail-item__value" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _renderSlot(_ctx.$slots, "icon", {}, undefined, true)
    ]),
    _createElementVNode("div", _hoisted_3, [
      _createElementVNode("div", _hoisted_4, _toDisplayString(_ctx.label) + ":", 1),
      _createElementVNode("div", _hoisted_5, [
        _renderSlot(_ctx.$slots, "default", {}, undefined, true)
      ])
    ])
  ]))
}