
import { defineComponent } from "vue";
import Windows10Loading from "@/components/Ui/Windows10Loading.vue";
import FaqItem from "@/components/Page/FAQ/FAQItem.vue";
import useMetaTitle from "@/composables/useMetaTitle";
import useBreakpoints from "@/composables/useBreakpoints";
import useApi from "@/composables/useApi";
import { FAQResponse } from "@/types/home";

export default defineComponent({
  name: "FAQPage",
  components: {
    Windows10Loading,
    FaqItem,
  },
  setup() {
    useMetaTitle("سوالات متداول");
    const breakpoints = useBreakpoints();
    const { call, response, status } = useApi<FAQResponse>();

    call({
      url: "/home/faq",
      method: "GET",
    });

    return {
      status,
      response,
      breakpoints,
    };
  },
});
