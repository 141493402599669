
import { defineComponent, watch } from "vue";
import { useRoute, useRouter } from "vue-router";
import Windows10Loading from "@/components/Ui/Windows10Loading.vue";
import useApi, { Status } from "@/composables/useApi";
import useMetaTitle from "@/composables/useMetaTitle";
import { Page } from "@/types/home";

export default defineComponent({
  name: "Page",
  components: {
    Windows10Loading,
  },
  setup() {
    const route = useRoute();
    const router = useRouter();
    const { call, status, response } = useApi<Page>();

    if (!route.query.id) {
      router.push({ name: "NotFound" });
    }

    const fetch = async () => {
      await call({
        url: "/home/pages",
        method: "POST",
        data: {
          id: route.query.id,
        },
      });

      if (response.value) {
        useMetaTitle(response.value.item.title);
      }

      if (status.value === Status.ERROR) {
        router.push({ name: "NotFound" });
      }
    };

    fetch();

    watch(
      () => route.query,
      () => {
        if (route.name === "Page") {
          fetch();
        }
      }
    );

    return {
      response,
      status,
    };
  },
});
