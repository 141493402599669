
import { defineComponent, defineAsyncComponent } from "vue";
import useMetaTitle from "@/composables/useMetaTitle";
import useLastTransaction from "@/composables/useLastTransaction";
import useBreakpoints from "@/composables/useBreakpoints";

export default defineComponent({
  name: "Home",
  components: {
    HomeMobile: defineAsyncComponent(
      () => import("@/components/Page/Home/HomeMobile.vue")
    ),
    HomeDesktop: defineAsyncComponent(
      () => import("@/components/Page/Home/HomeDesktop.vue")
    ),
  },
  setup() {
    useMetaTitle("خرید ارز دیجیتال");
    const { fetch } = useLastTransaction();
    const breakpoint = useBreakpoints();

    fetch();

    return {
      breakpoint,
    };
  },
});
