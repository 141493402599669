import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode, Fragment as _Fragment, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-1a6eb8a6"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 2,
  class: "desktop"
}
const _hoisted_2 = { key: 3 }
const _hoisted_3 = { class: "desktop-support" }
const _hoisted_4 = { class: "container px-0" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_alert = _resolveComponent("alert")!
  const _component_the_header = _resolveComponent("the-header")!
  const _component_router_view = _resolveComponent("router-view")!
  const _component_chat_message_box = _resolveComponent("chat-message-box")!
  const _component_the_footer = _resolveComponent("the-footer")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (_ctx.errorMessage)
      ? (_openBlock(), _createBlock(_component_alert, {
          key: 0,
          onClose: _cache[0] || (_cache[0] = ($event: any) => (_ctx.errorMessage = undefined)),
          variant: "danger",
          title: _ctx.errorMessage
        }, null, 8, ["title"]))
      : _createCommentVNode("", true),
    _createVNode(_component_the_header),
    (_ctx.breakpoints.isMobile)
      ? (_openBlock(), _createBlock(_component_router_view, { key: 1 }))
      : (_ctx.breakpoints.isDesktop && _ctx.$route.name !== 'Support')
        ? (_openBlock(), _createElementBlock("main", _hoisted_1, [
            _createVNode(_component_router_view)
          ]))
        : (_ctx.breakpoints.isDesktop && _ctx.$route.name === 'Support')
          ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
              _createElementVNode("main", _hoisted_3, [
                _createVNode(_component_router_view)
              ]),
              _createElementVNode("div", _hoisted_4, [
                _createVNode(_component_chat_message_box, {
                  onSendMessage: _ctx.send,
                  onError: _ctx.onError
                }, null, 8, ["onSendMessage", "onError"])
              ])
            ]))
          : _createCommentVNode("", true),
    _createVNode(_component_the_footer)
  ], 64))
}