
import { defineComponent } from "vue";

export default defineComponent({
  name: "HeaderButton",
  props: {
    to: {
      required: false,
      type: [String, Object],
      default: null,
    },
  },
  emits: ["click"],
});
