
import { defineComponent } from "vue";
import HeaderButton from "@/components/Layout/Default/Header/HeaderButton.vue";

export default defineComponent({
  name: "Telephone",
  components: {
    HeaderButton,
  },
});
