
import { defineComponent } from "vue";
import TheHeader from "@/components/Layout/Default/Header/TheHeader.vue";
import TheFooter from "@/components/Layout/Default/Footer/TheFooter.vue";
import ChatMessageBox from "@/components/Page/Account/Support/ChatMessageBox.vue";
import Alert from "@/components/Ui/Alert.vue";
import useBreakpoints from "@/composables/useBreakpoints";
import useChat from "@/composables/useChat";
import useScrollToBottom from "@/composables/useScrollToBottom";
import { Chat } from "@/types/chat";

export default defineComponent({
  name: "DefaultLayout",
  components: {
    TheHeader,
    TheFooter,
    ChatMessageBox,
    Alert,
  },
  setup() {
    const breakpoints = useBreakpoints();
    const { onSendMessage, onError, errorMessage } = useChat();

    const send = async (chat: Chat) => {
      await onSendMessage(chat);
      setTimeout(() => {
        useScrollToBottom(".chat");
      }, 50);
    };

    return {
      breakpoints,
      onSendMessage,
      onError,
      errorMessage,
      send,
    };
  },
});
