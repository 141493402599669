
import { defineComponent } from "vue";
import Telephone from "@/components/Ui/Telephone.vue";
import FooterSocial from "./FooterSocial.vue";

export default defineComponent({
  name: "MobileFooter",
  components: {
    Telephone,
    FooterSocial,
  },
});
