
import { defineComponent, ref, watch } from "vue";
import { onBeforeRouteLeave } from "vue-router";
import MobileHeader from "./MobileHeader.vue";
import DesktopHeader from "./DesktopHeader.vue";
import TheMenu from "@/components/Layout/Default/Header/TheMenu.vue";
import useBreakpoints from "@/composables/useBreakpoints";
import useAuth from "@/composables/useAuth";

export default defineComponent({
  name: "TheHeader",
  components: {
    MobileHeader,
    DesktopHeader,
    TheMenu,
  },
  setup() {
    const show = ref(false);
    const { user } = useAuth();
    const breakpoint = useBreakpoints();

    watch(
      () => show.value,
      (value) => {
        const html = document.querySelector("html");
        value
          ? ((html as HTMLElement).style.overflowY = "hidden")
          : (html as HTMLElement).style.removeProperty("overflow-y");
      }
    );

    onBeforeRouteLeave(() => {
      document.querySelector("html")?.style.removeProperty("overflow-y");
    });

    return {
      show,
      user,
      breakpoint,
    };
  },
});
