import { toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, normalizeStyle as _normalizeStyle, createElementVNode as _createElementVNode, mergeProps as _mergeProps, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, Transition as _Transition, withCtx as _withCtx, createVNode as _createVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-7551cccc"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "form-input" }
const _hoisted_2 = ["for"]
const _hoisted_3 = ["id", "autofocus", "value"]
const _hoisted_4 = {
  key: 0,
  class: "form-input__hint"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", {
      class: _normalizeClass([
        'form-input__inner',
        _ctx.active && 'form-input__inner--active',
        _ctx.disabled && 'form-input__inner--disabled',
        _ctx.error && 'form-input__inner--error',
      ]),
      style: _normalizeStyle({ borderColor: _ctx.borderColor, height: 'unset' })
    }, [
      _createElementVNode("label", {
        class: _normalizeClass([
          'form-input__label form-input__label-textarea',
          (_ctx.active || _ctx.modelValue) && 'form-input__label--active',
          _ctx.disabled && 'form-input__label--disabled',
          _ctx.error && 'form-input__label--error',
        ]),
        for: _ctx.id,
        style: _normalizeStyle([
          { backgroundColor: _ctx.bgColor },
          !_ctx.bgColor && { backgroundColor: '#fff' },
          _ctx.modelValue && _ctx.modelValue.length && !_ctx.active && { color: _ctx.color },
        ])
      }, _toDisplayString(_ctx.label), 15, _hoisted_2),
      _createElementVNode("textarea", _mergeProps({
        class: "form-input__html form-input__html-textarea",
        style: [
          _ctx.appendLabel && 'padding-left: 50px',
          _ctx.fontEnglish && { fontFamily: 'IRANSansX' },
          { backgroundColor: _ctx.bgColor },
        ],
        id: _ctx.id,
        autofocus: _ctx.autofocus,
        value: _ctx.modelValue,
        onInput: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('update:modelValue', $event.target.value))),
        onFocusout: _cache[1] || (_cache[1] = ($event: any) => (_ctx.active = false)),
        onFocus: _cache[2] || (_cache[2] = ($event: any) => (_ctx.active = true))
      }, _ctx.$attrs), "\n      ", 16, _hoisted_3),
      (_ctx.appendLabel)
        ? (_openBlock(), _createElementBlock("span", {
            key: 0,
            class: _normalizeClass([
          'form-input__append-label',
          _ctx.readonly && 'form-input__append-label--readonly',
        ])
          }, _toDisplayString(_ctx.appendLabel), 3))
        : _createCommentVNode("", true)
    ], 6),
    _createVNode(_Transition, {
      "enter-active-class": "animate__animated animate__fadeIn",
      "leave-active-class": "animate__animated animate__fadeOut",
      class: "animate__faster",
      mode: "out-in"
    }, {
      default: _withCtx(() => [
        (_ctx.hint && !_ctx.errorMessage)
          ? (_openBlock(), _createElementBlock("div", _hoisted_4, _toDisplayString(_ctx.hint), 1))
          : (_ctx.errorMessage)
            ? (_openBlock(), _createElementBlock("div", {
                key: 1,
                class: _normalizeClass(['form-input__hint', _ctx.error && 'form-input__hint--error'])
              }, _toDisplayString(_ctx.errorMessage), 3))
            : _createCommentVNode("", true)
      ]),
      _: 1
    })
  ]))
}