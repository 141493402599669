
import { defineComponent, PropType } from "vue";
import Logo from "@/components/Ui/Logo.vue";
import HeaderButton from "./HeaderButton.vue";
import { UserDetailsResponse } from "@/types/auth";

export default defineComponent({
  name: "MobileHeader",
  components: {
    Logo,
    HeaderButton,
  },
  props: {
    user: {
      type: Object as PropType<UserDetailsResponse>,
      required: false,
      default: null,
    },
  },
  emits: ["open"],
});
