
import { defineComponent } from "vue";
import { SOCIAL } from "@/constants";

export default defineComponent({
  name: "DesktopFooterSocial",
  setup() {
    return {
      SOCIAL,
    };
  },
});
