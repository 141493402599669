import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode, Fragment as _Fragment, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-a52cb4ba"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "page" }
const _hoisted_2 = {
  key: 0,
  class: "page__time"
}
const _hoisted_3 = { class: "page__title" }
const _hoisted_4 = ["innerHTML"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_windows_10_loading = _resolveComponent("windows-10-loading")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.status === 'loading')
      ? (_openBlock(), _createBlock(_component_windows_10_loading, {
          key: 0,
          class: "position-relative mt-5 pt-5"
        }))
      : (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
          (_ctx.response.item.date)
            ? (_openBlock(), _createElementBlock("time", _hoisted_2, _toDisplayString(_ctx.response.item.date), 1))
            : _createCommentVNode("", true),
          _createElementVNode("h2", _hoisted_3, _toDisplayString(_ctx.response.item.title), 1),
          _createElementVNode("div", {
            class: "page__content",
            innerHTML: _ctx.response.item.text
          }, null, 8, _hoisted_4)
        ], 64))
  ]))
}