
import { defineComponent } from "vue";
import Logo from "@/components/Ui/Logo.vue";
import useMetaTitle from "@/composables/useMetaTitle";

export default defineComponent({
  name: "404Page",
  components: {
    Logo,
  },
  setup() {
    useMetaTitle("404");
  },
});
