import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_home_mobile = _resolveComponent("home-mobile")!
  const _component_home_desktop = _resolveComponent("home-desktop")!

  return (_ctx.breakpoint.isMobile)
    ? (_openBlock(), _createBlock(_component_home_mobile, { key: 0 }))
    : (_ctx.breakpoint.isDesktop)
      ? (_openBlock(), _createBlock(_component_home_desktop, { key: 1 }))
      : _createCommentVNode("", true)
}