
import { defineComponent, ref } from "vue";
import { useForm, useField } from "vee-validate";
import { string, object } from "yup";
import FormInput from "@/components/Ui/FormInput.vue";
import FormTextarea from "@/components/Ui/FormTextarea.vue";
import FormButton from "@/components/Ui/FormButton.vue";
import Alert from "@/components/Ui/Alert.vue";
import useBreakpoints from "@/composables/useBreakpoints";
import useConfig from "@/composables/useConfig";
import useApi, { Status } from "@/composables/useApi";
import { ContactResponse } from "@/types/home";
import { PERSIAN_REGEX, MOBILE_REGEX } from "@/regex";

const validationSchema = object({
  name: string()
    .min(2, "نام و نام خانوادگی نمی‌تواند کمتر از ۲ کاراکتر باشد")
    .matches(PERSIAN_REGEX, { message: "فقط حروف فارسی مجاز است" })
    .required("نام و نام خانوادگی را وارد کنید"),
  mobile: string()
    .matches(MOBILE_REGEX, {
      message: "شماره همراه نامعتبر است.",
    })
    .required("پر کردن اطلاعات این فیلد اجباری است."),
  msg: string()
    .min(5, "متن پیام نمی‌تواند کمتر از ۵ کاراکتر باشد")
    .required("متن پیام را وارد کنید"),
});

export default defineComponent({
  name: "ContactForm",
  components: {
    FormInput,
    FormTextarea,
    FormButton,
    Alert,
  },
  setup() {
    const { errors, meta, handleSubmit, resetForm } = useForm({
      validationSchema,
    });
    const { value: name } = useField("name");
    const { value: mobile } = useField("mobile");
    const { value: msg } = useField("msg");

    const alert = ref<{ variant: "success" | "danger"; msg: string }>();
    const loading = ref<boolean>(false);

    const breakpoints = useBreakpoints();
    const { call, status, error } = useApi<ContactResponse>();
    const { googlerecaptcha_site_key } = useConfig();

    const onSubmit = handleSubmit((values) => {
      loading.value = true;

      window.grecaptcha.ready(() => {
        window.grecaptcha
          .execute(googlerecaptcha_site_key.value, {
            action: "submit",
          })
          .then(async (token) => {
            await call({
              url: "/home/contact",
              method: "POST",
              data: {
                ...values,
                google_response: token,
              },
            });

            if (status.value === Status.SUCCESS) {
              alert.value = { variant: "success", msg: "با موفقیت انجام شد!" };
              resetForm();
            } else if (status.value === Status.ERROR) {
              const {
                errors: { msg },
              } = error.value as unknown as { errors: { msg: string[] } };

              alert.value = { variant: "danger", msg: msg[0] };
            }
          })
          .then(() => {
            loading.value = false;
          });
      });
    });

    return {
      errors,
      meta,
      name,
      mobile,
      msg,
      onSubmit,
      breakpoints,
      alert,
      loading,
    };
  },
});
