
import { defineComponent, PropType } from "vue";
import Logo from "@/components/Ui/Logo.vue";
import UserMenu from "./UserMenu.vue";
import { UserDetailsResponse } from "@/types/auth";
import useUnreadMessages from "@/composables/useUnreadMessages";

export default defineComponent({
  name: "DesktopHeader",
  components: {
    Logo,
    UserMenu,
  },
  props: {
    user: {
      type: Object as PropType<UserDetailsResponse>,
      required: false,
      default: null,
    },
  },
  setup() {
    const { unreadCount } = useUnreadMessages();

    return {
      unreadCount,
    };
  },
});
