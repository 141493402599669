
import { defineComponent, getCurrentInstance, ref } from "vue";

export default defineComponent({
  name: "FormInput",
  props: {
    modelValue: {
      required: false,
      type: [String, Number],
      default: null,
    },
    label: {
      required: true,
      type: String,
    },
    appendLabel: {
      required: false,
      type: String,
      default: null,
    },
    autofocus: Boolean,
    disabled: Boolean,
    error: Boolean,
    readonly: Boolean,
    fontEnglish: Boolean,
    errorMessage: {
      required: false,
      type: String,
      default: null,
    },
    hint: {
      required: false,
      type: String,
      default: null,
    },
    bgColor: {
      required: false,
      type: String,
      default: "#fff",
    },
    borderColor: {
      required: false,
      type: String,
      default: "#e0e0e0",
    },
    color: {
      required: false,
      type: String,
      default: "#afb7bd",
    },
  },
  setup(props) {
    const instance = getCurrentInstance();
    const active = ref<boolean>(props.disabled ? false : props.autofocus);

    if (props.readonly) {
      active.value = false;
    }

    return {
      active,
      id: `input-${instance?.uid}`,
    };
  },
});
