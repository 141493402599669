import { reactive, toRefs } from "vue";
import useApi, { Status } from "@/composables/useApi";
import { LastTransactionResponse } from "@/types/home";

const state = reactive<LastTransactionResponse>({
  items: [],
  videos: [],
});

export default () => {
  const { call, response, status } = useApi<LastTransactionResponse>();

  const fetch = async () => {
    await call({
      url: "/home/last-transaction",
      method: "GET",
    });

    if (status.value === Status.SUCCESS && response.value) {
      state.items = response.value.items;
      state.videos = response.value.videos;
    }
  };

  return {
    fetch,
    ...toRefs(state),
  };
};
