
import { defineComponent, onMounted } from "vue";
import { Tooltip } from "bootstrap";
import { SOCIAL } from "@/constants";

export default defineComponent({
  name: "ContactSocialMedia",
  setup() {
    onMounted(() => {
      Array.from(
        document.querySelectorAll('[data-bs-toggle="tooltip"]')
      ).forEach((tooltipNode) => new Tooltip(tooltipNode));
    });

    return {
      SOCIAL,
    };
  },
});
