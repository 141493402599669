import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, Transition as _Transition, withCtx as _withCtx, createVNode as _createVNode, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_mobile_header = _resolveComponent("mobile-header")!
  const _component_desktop_header = _resolveComponent("desktop-header")!
  const _component_the_menu = _resolveComponent("the-menu")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (_ctx.breakpoint.isMobile)
      ? (_openBlock(), _createBlock(_component_mobile_header, {
          key: 0,
          user: _ctx.user,
          onOpen: _cache[0] || (_cache[0] = ($event: any) => (_ctx.show = true))
        }, null, 8, ["user"]))
      : (_ctx.breakpoint.isDesktop)
        ? (_openBlock(), _createBlock(_component_desktop_header, {
            key: 1,
            user: _ctx.user
          }, null, 8, ["user"]))
        : _createCommentVNode("", true),
    _createVNode(_Transition, {
      "enter-active-class": "animate__animated animate__fadeInRight",
      "leave-active-class": "animate__animated animate__fadeOutRight",
      class: "animate__faster",
      mode: "out-in"
    }, {
      default: _withCtx(() => [
        (_ctx.show)
          ? (_openBlock(), _createBlock(_component_the_menu, {
              key: 0,
              onClose: _cache[1] || (_cache[1] = ($event: any) => (_ctx.show = false))
            }))
          : _createCommentVNode("", true)
      ]),
      _: 1
    })
  ], 64))
}