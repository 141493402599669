
import { defineComponent, watch } from "vue";
import { useRoute } from "vue-router";
import Logo from "@/components/Ui/Logo.vue";
import HeaderButton from "./HeaderButton.vue";
import Telephone from "@/components/Ui/Telephone.vue";
import useAuth from "@/composables/useAuth";

const list: {
  title: string;
  to: string | { name: string; query?: { [key: string]: string } };
}[] = [
  {
    title: "خانه",
    to: { name: "Home" },
  },
  {
    title: "شرایط و قوانین",
    to: { name: "Page", query: { id: "rules" } },
  },
  {
    title: "سوالات متداول",
    to: { name: "FAQ" },
  },
  {
    title: "تماس با ما",
    to: { name: "Contact" },
  },
  {
    title: "درباره ما",
    to: { name: "Page", query: { id: "aboutus" } },
  },
];

export default defineComponent({
  name: "TheMenu",
  components: {
    Logo,
    HeaderButton,
    Telephone,
  },
  emits: ["close"],
  setup(_, { emit }) {
    const { user, logout } = useAuth();
    const route = useRoute();

    watch(
      () => route.name,
      () => {
        emit("close");
      }
    );

    watch(
      () => route.query,
      () => {
        emit("close");
      }
    );

    return {
      list,
      auth: false,
      user,
      logout,
    };
  },
});
