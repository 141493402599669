
import { defineComponent, PropType, ref, watch } from "vue";
import { directive } from "vue3-click-away";
import FormButton from "@/components/Ui/FormButton.vue";
import Unlimited from "@/components/Shared/Unlimited.vue";
import Logout from "@/components/Shared/Logout.vue";
import AccountActivationModal from "@/components/Shared/AccountActivationModal.vue";
import ResolvingLimitationModal from "@/components/Shared/ResolvingLimitationModal.vue";
import { UserDetailsResponse } from "@/types/auth";
import useAuth from "@/composables/useAuth";

export default defineComponent({
  name: "UserMenu",
  components: {
    FormButton,
    Unlimited,
    Logout,
    AccountActivationModal,
    ResolvingLimitationModal,
  },
  props: {
    user: {
      type: Object as PropType<UserDetailsResponse>,
      required: false,
      default: null,
    },
  },
  directives: {
    ClickAway: directive,
  },
  setup() {
    const isOpen = ref<boolean>(false);
    const activationForm = ref<boolean>(false);
    const resolvingLimitationForm = ref<boolean>(false);
    const isResolvingLimitationButtonDisabled = ref<boolean>(false);

    const { user } = useAuth();

    watch(
      () => user.value,
      (value) => {
        if (value) {
          isResolvingLimitationButtonDisabled.value =
            value.item.verification_bottom === "show_and_inactive";
        }
      },
      {
        immediate: true,
      }
    );

    return {
      isOpen,
      activationForm,
      resolvingLimitationForm,
      isResolvingLimitationButtonDisabled,
      onClickOutside: () => {
        isOpen.value = false;
      },
    };
  },
});
