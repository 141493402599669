
import { defineComponent } from "vue";
import MobileFooter from "@/components/Layout/Default/Footer/MobileFooter.vue";
import DesktopFooter from "@/components/Layout/Default/Footer/DesktopFooter.vue";
import useBreakpoints from "@/composables/useBreakpoints";

export default defineComponent({
  name: "TheFooter",
  components: {
    MobileFooter,
    DesktopFooter,
  },
  setup() {
    const breakpoints = useBreakpoints();

    return {
      breakpoints,
    };
  },
});
